<template>
    <div class="header dis_b_c ">
        <div class="dis_c logo">
            <img src="@/assets/logo.png" alt="">

        </div>
        <div class="nav dis_c">
            <div @click="toLogin">首页</div>
            <div :class="index == 2 ? 'active' : ''" @click="toArticle(2)">关于</div>
            <div :class="index == 3 ? 'active' : ''" @click="toArticle(5)">服务</div>
            <el-tooltip class="item" effect="dark" content="15844398786" placement="bottom-end">
                <a href="tel://15844398786" :class="index == 4 ? 'active' : ''">联系</a>
            </el-tooltip>
            <a :class="index == 4 ? 'active' : ''" @click="showUs">效果图24小时在线</a>
        </div>

        <el-drawer :visible.sync="drawer" :with-header="false" :append-to-body="true" :modal-append-to-body="false">
            <div class="about">
                <img src="@/assets/logo.png" alt="">
                <div>
                    吉林亿鑫效果图有限公司是成立于2015年，是一家专业的效果图设计服务公司，专业从事室内外效果图与施工图设计制作，目前服务于全国装企和设计师1000余家，线上线下两百人专业团队，一对一服务，享有本站VIP会员者，专享做图价格9折优惠，送500元优惠券，详情请加客服微信
                </div>
                <div class="qrcode">
                    <img src="@/assets/imgs/qrcode2.jpg" alt="">
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            drawer: false
        }
    },
    props: ['index'],
    methods: {
        toArticle(type) {
            this.$router.push({
                name: "article",
                query: {
                    type: type
                }
            })
        },
        toLogin() {
            this.$router.push({
                name: "login"
            })
        },
        showUs() {
            this.drawer = true
        }

              

    }
}
</script>
<style scoped>
.header {
    padding: 10px 100px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: #3a3a3a;
    background-color: #fff;
    z-index: 1000;
}

.header .logo {
    font-size: 1.2em;
    font-weight: 600;
}

.header img {
    height: 40px;
    margin-right: 10px;
}

.nav {
    font-size: 0.8em;

}

.nav div,
.nav a {
    margin: 0 40px;
    color: #3a3a3a;
    cursor: pointer;
}

.active {
    color: red !important;
}

.about {
    padding: 20px;
    box-sizing: border-box;
    line-height: 1.8;
}

.about img {
    height: 50px;
    object-fit: cover;
    margin-bottom: 20px;
}

.qrcode img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-left: 10px;
}</style>