import Vue from 'vue'
import App from './App.vue'
import router from "./router/index"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import topNav from "/src/components/topNav.vue"
import rightNav from "/src/components/rightNav.vue"
import topNav2 from "/src/components/topNav2.vue"

Vue.component("top-nav", topNav)
Vue.component("right-nav", rightNav)
Vue.component("top-nav2", topNav2)

Vue.use(ElementUI);

import * as API from '@/api';
Vue.config.productionTip = false


new Vue({
  router,
  beforeCreate() {
    Vue.prototype.$bus = this
    Vue.prototype.$API = API;
    Vue.prototype.$baseUrl = 'https://www.720eyun.com';
  },
  render: h => h(App),
}).$mount('#app')
