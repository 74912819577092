//对于axios进行二次封装
import axios from "axios";
//底下的代码也是创建axios实例
let requests = axios.create({
    //基础路径
    baseURL: "",
    // https://zhuan.igetv.com/nMdupLJkZw.php
    //请求不能超过5S
    timeout: 5000,
});

//请求拦截器----在项目中发请求（请求没有发出去）可以做一些事情
requests.interceptors.request.use((config) => {


    //需要携带token带给服务器
    if (localStorage.getItem("token")) {
        let token = localStorage.getItem("token")
        if (!config.data) {
            config.data = new Object()
        }

        config.data.token = token

    }


    return config;

});


export default requests;
