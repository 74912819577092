<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
@import url("@/assets/css/flex.css");
@import url("@/assets/css/iconfont.css");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 0;
  font-size: 16px;
}
*{
  padding: 0;
  margin:0;
}
body {
  padding: 0;
  margin: 0;
}
a{
  text-decoration: none;
  cursor: pointer;
  color:#fff;
}

input {
  flex: 1;
  background-color: transparent;
  border: none;
  outline:none;

}
li{
  text-decoration: none;
  list-style: none;
}
ul{
  padding:0;
}
</style>
