<template>
    <div class="nav " :style="showAll ? 'height:350px' : 'height:50px;overflow:hidden'">
        <div class="iconfont icon-arrow-down" :class="showAll ? 'up' : 'down'" @click="changeShow">

        </div>
        <el-tooltip class="item" effect="dark" content="E云装企助手1.0版本" placement="left">
            <a class="iconfont icon-geren">

            </a>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="QQ:807381045" placement="left">
            <a class="iconfont icon-kefu">

            </a>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="15844398786" placement="left">

            <a class="iconfont icon-dianhua" href="tel://15844398786">

            </a>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="效果图咨询" placement="top-end">
            <div class="iconfont icon-xiaoxi1 qrcode">

                <div class="qrcode-img">
                    <img src="@/assets/imgs/weixin2.png" alt="">
                </div>

            </div>
        </el-tooltip>
        <div class="iconfont icon-weibiaoti-" @click="toArticle(2)">

        </div>
        <el-tooltip class="item" effect="dark" content="官方微信" placement="top-end">
            <div class="iconfont icon-erweima qrcode">
                <div class="qrcode-img dis_c">
                    <img src="@/assets/imgs/qrcode2.jpg" alt="">
                </div>

            </div>
        </el-tooltip>

    </div>
</template>
<script>
export default {
    data() {
        return {
            showAll: false,
        }
    },
    methods: {
        toArticle(type) {
            this.$router.push({
                name: "article",
                query: {
                    type: type
                }
            })
        },
        changeShow() {
            this.showAll = !this.showAll
        },
        open1() {
            this.$notify({
                message: 'E云装企助手1.0版本'
            });
        },
    }
}
</script>
<style scoped>
.nav {
    position: fixed;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    height: 350px;
    transition: all 0.2s ease;
}

.nav .iconfont {
    background-color: #fff;
    color: #3a3a3a;
    width: 40px;
    height: 40px;
    margin: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.qrcode {
    position: relative;
}

.qrcode:hover .qrcode-img {
    display: flex;
}

.qrcode-img {
    position: absolute;
    right: calc(100% + 50px);
    top: 0;


    border-radius: 5px;
    overflow: hidden;
    display: none;

}

.qrcode-img img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-left: 10px;
}

.down {
    transform: rotate(0deg);
}

.up {
    transform: rotate(180deg);
}
</style>