import requests from './request';


//新品1
export const login = (data) => requests({ url: `/api/user/login`, data: data, method: "post" })

export const register = (data) => requests({ url: `/api/user/register`, data: data, method: "post" })

export const getCateList = (data) => requests({ url: `/api/index/getCategoryList`, data: data, method: "post" })

export const getList = (data) => requests({ url: `/api/index/products`, data: data, method: "post" })

export const getStyle = (data) => requests({ url: `/api/index/getStyleList`, data: data, method: "post" })

export const getDetail = (data) => requests({ url: `/api/index/detail`, data: data, method: "post" })

export const getHot = (data) => requests({ url: `/api/index/getKeywordsList`, data: data, method: "post" })

export const checkLogin = (data) => requests({ url: `/api/user/checkLoginToken`, data: data, method: "post" })

export const getIndexType = (data) => requests({ url: `/api/index/index/product_type_list`, data: data, method: "post" })
