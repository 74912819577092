<template>
    <div>
        <div class="header dis_b_c ">
            <div class="dis_c ">
                <img src="@/assets/logo.png" class="logo" alt="" @click="toIndex">
                <ul class="nav_left dis_c">
                    <li v-for="(item, index) in cate" :key="item.id" @mouseleave="active_cate = 0">
                        <a @click="toCate3(1, item.id)">{{ item.name }}</a>
                        <div class="subnav-model-classification">
                            <div class="nav-class-content">
                                <div class="list-class-content">
                                    <div class="cate_channel dis">
                                        <ul class="" v-for="(item2, index2) in item.child" :key="item2.id"
                                            v-if="index2 < 8">
                                            <li>
                                                <a @click="toCate3(1, item.id, item2.id)">{{ item2.name }}</a>
                                                <a v-for="(item3, index3) in item2.child" :key="index3"
                                                    @click="toCate3(1, item.id, item2.id, item3.id)">{{
                                                        item3.name }}</a>
                                            </li>

                                        </ul>
                                        <a class="every_class" @click="toCate3(1, item.id)"> 查看全部分类 <span
                                                class="iconfont icon-arrow-right-copy"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="nav dis_c">
                <div class="dis_c">
                    <div :class="index == 1 ? 'active' : ''" @click="toIndex">首页</div>
                    <div :class="index == 2 ? 'active' : ''" @click="toArticle(2)">关于</div>
                    <div :class="index == 3 ? 'active' : ''" @click="toArticle(5)">服务</div>
                </div>
                <div class="dis_c">
                    <el-tooltip class="item" effect="dark" content="15844398786" placement="bottom-end">
                        <a href="tel://15844398786" :class="index == 4 ? 'active' : ''">联系</a>
                    </el-tooltip>
                    <div @click="logout">退出登录</div>
                </div>
            </div>
        </div>

        <!-- <div class="header2 ">
            <div class="dis_b_c header_btn">
                <img src="@/assets/logo.png" alt="" class="mob_logo">
                <div class="iconfont icon-liebiao" @click="showMenu = !showMenu"></div>
            </div>
            <div class="mob_menu">
                <el-collapse v-model="activeNames" accordion>
                    <el-collapse-item :title="item.name" :name="item.id" v-for="(item, index) in cate" :key="index"
                        :disabled="!item.child" @click="aci(1, item.id)">
                        <div style="padding:0 40px;box-sizing:border-box;">
                            <el-collapse v-model="activeName2" accordion>
                                <el-collapse-item :title="item2.name" name="1" v-for="(item2, index2) in item.child"
                                    :key="index2" :disabled="!item2.child">
                                    <div style="padding:0 40px;box-sizing:border-box;">
                                        <el-collapse v-model="activeName3" accordion>
                                            <el-collapse-item :title="item3.name" name="1"
                                                v-for="(item3, index3) in item2.child" :key="index3"
                                                :disabled="!item3.child">

                                                <div style="padding:0 40px;box-sizing:border-box;">
                                                    <el-collapse v-model="activeName3" accordion>
                                                        <el-collapse-item :title="item4.name" name="1"
                                                            v-for="(item4, index4) in item3.child" :key="index4"
                                                            :disabled="!item4.child">



                                                        </el-collapse-item>
                                                    </el-collapse>
                                                </div>

                                            </el-collapse-item>
                                        </el-collapse>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            cate: [],
            active_cate: 0,
            showMenu: false,
            activeNames: "",
            activeName2: '',
            activeName3: '',
            activeName4: ''
        }
    },
    props: ['index'],
    mounted() {
        this.getCates()

    },
    methods: {
        aci(a, b) {
            console.log(a, b, 'qw')
        },
        toIndex() {
            this.$router.push({
                name: "cate"
            })
        },
        logout() {
            localStorage.removeItem("token")
            let backlen = window.history.length - 2;
            window.history.go(- backlen);
            this.$router.replace('/', () => {
                window.location.reload()
            })
        },
        moveNav(index) {
            this.active_cate = index
        },
        toCate2() {
            this.$router.push({
                name: "cate2"
            })
        },
        toCate3(type, cate1 = -1, cate2 = -1, cate3 = -1) {
            let data = {
                cate1: cate1,
                cate2: cate2,
                cate3: cate3
            }
            this.$router.push({
                name: "model",
                query: data
            })
            this.$bus.$emit("changeCate", data)
        },
        async getCates() {
            let data = {}
            let result = await this.$API.getCateList(data)
            this.cate = result.data.data
        },
        toArticle(type) {
            this.$router.push({
                name: "article",
                query: {
                    type: type
                }
            })
        },
    }
}
</script>
<style scoped>
.header {
    padding: 0px 20px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    color: #fff;
    background-color: rgba(0, 0, 0, .6);
    backdrop-filter: blur(10px);
    z-index: 1000;

}

.header .logo {
    font-size: 1em;
    cursor: pointer;
}

.header img {
    height: 40px;
    margin-right: 10px;
}

.nav {
    font-size: 0.8em;
    flex-wrap: wrap;
    line-height: 50px;
}


.nav div div,
.nav a {
    margin: 0 20px;
    cursor: pointer;
    color: #cc6d07;
    white-space: nowrap;
}

.active {}

.nav_left {
    font-size: 1em;
    margin: 0;
    height: 100%;
    flex-wrap: wrap;

}

.nav_left>li {
    position: relative;
    margin: 0 20px;
    padding: 20px 0;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

.nav_left>li:hover {
    color: #0eb75f;
}

.nav_left>li:hover .subnav-model-classification {
    display: block;
}

.subnav-model-classification {
    position: absolute;
    top: 100%;
    padding: 6px;
    left: -200px;
    color: #3a3a3a;
    display: none;

}

.subnav-model-classification a {
    color: #3a3a3a;
}

.nav-class-content {
    background: #FFF;
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .1);
    padding: 24px 0;
    width: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 12px;

}

.nav-class-left {
    width: 193px;
    height: 100%;

    padding: 0;
    box-sizing: border-box;
}

.nav-class-left li {
    height: 50px;
    padding: 0 20px 0 10px;
    box-sizing: border-box;
    font-size: 1.0em;
    font-weight: 600;
    cursor: pointer;
}

.nav-left-active {
    color: #0eb75f;
    background-color: #e9fbf1;
}

.list-class-content {
    position: absolute;
    background-color: #fff;
    white-space: nowrap;
    top: 0px;
    left: 0;
    border-radius: 12px;
    min-height: 200px;
    -webkit-box-shadow: 5px 4px 8px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 5px 4px 8px 0 rgba(0, 0, 0, 0.1);
    border-left: 1px solid #ddd;
    z-index: 10;
    min-width: 200px;
}

.cate_channel {
    padding: 20px 40px 40px;
}

.active_cate {
    display: block;
}

.cate_channel>ul {
    margin-right: 30px;
}

.cate_channel>ul>li:last-child {
    margin-right: 0px;
}

.cate_channel>ul>li>a {
    font-size: 0.9em;
    margin: 10px 0;
    display: block;
}

.cate_channel>ul>li>a:hover {
    color: #0eb75f;
}

.cate_channel>ul>li>a:first-child {
    margin: 10px 0;
    border-bottom: solid 1px #ddd;
    font-size: 1em;
    font-weight: 600;
    padding-bottom: 10px;
}

.every_class {
    color: #86909C;
    font-size: 14px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
}

.mob_logo {
    height: 80px;
    width: 160px;
    object-fit: contain;

}

.icon-liebiao {
    font-size: 50px;
    color: #fff;
}

.header2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, .8);
    transition: all 0.2s ease;
    overflow: hidden;
    color: #fff;

}

.header_btn {
    height: 100px;
    padding: 0 50px;
}

@media screen and (min-width:1000px) {
    .header2 {
        display: none;
    }

    .header {
        display: flex;
    }
}
</style>