import Vue from "vue"
import vueRouter from "vue-router"

import * as API from '@/api';
Vue.use(vueRouter)

let originPush = vueRouter.prototype.push;
let originreplace = vueRouter.prototype.replace;

// 重写push
vueRouter.prototype.push = function (location, resolve, rereject) {
   if (resolve && rereject) {
      originPush.call(this, location, resolve, rereject)
   } else {
      originPush.call(this, location, () => {
      }, () => {
      })
   }
}
// 重写replace
vueRouter.prototype.replace = function (location, resolve, rereject) {
   if (resolve && rereject) {
      originreplace.call(this, location, resolve, rereject)
   } else {
      originreplace.call(this, location, () => {
      }, () => {
      })
   }
}

const routes = [
   {
      path: "/",
      name: "login",
      component: () => import("@/views/index/login.vue")

   },
   {
      path: "/cate",
      name: "cate",
      component: () => import("@/views/cate/index.vue")

   },
   {
      path: "/index",
      name: "index",
      component: () => import("@/views/index/index.vue")

   },
   {
      path: "/article",
      name: "article",
      component: () => import("@/views/article/index.vue")

   },
   {
      path: "/cate2",
      name: "cate2",
      component: () => import("@/views/cate/cate.vue")

   },
   {
      path: "/model",
      name: "model",
      component: () => import("@/views/cate/model.vue"),

      meta: {
         keepAlive: true    //true为需要缓存，false为不需要缓存（不需要的也可以不加）
      },

   },
   {
      path: "/detail",
      name: "detail",
      component: () => import("@/views/cate/detail.vue")

   },


]
const router = new vueRouter({
   mode: "hash",
   routes
})

// 路由守卫判断跳转的是不是home，是的话就判断是否有menu，没有的话就不跳转
router.beforeEach(async (to, from, next) => {

   let userInfo = JSON.parse(localStorage.getItem("userInfo")) || ''


   let data = {
      user_id: userInfo.user_id || 0
   }
   console.log(data)
   let isLogin = await API.checkLogin(data)
   console.log(isLogin, "islogin")


   if (isLogin.data.code == 1 || to.name == 'login' || to.name == 'article') {

      next()
   } else {
      if (from.name == "login") {

      } else {
         router.push({
            name: "login"
         })
      }
   }
})

export default router
